import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';

import { PrivaAuthHttpOptions } from '@priva/auth/http';
import { PrivaLocalizationOptions, VersionBasedTranslationLoader } from '@priva/localization';

import { PlatformService } from 'app/state/platform.service';

const initializeAppFactory = (platformService: PlatformService) => () => platformService.initialize();

export const provideAppInitializer = (): Provider[] => [
    {
        provide: APP_INITIALIZER,
        useFactory: initializeAppFactory,
        deps: [PlatformService],
        multi: true,
    },
];

export function getCustomAuthOptions(): PrivaAuthHttpOptions {
    const options = new PrivaAuthHttpOptions();
    options.bffConfig = { csrfValue: undefined };
    return options;
}

export function getCustomLocalizationOptions(): PrivaLocalizationOptions {
    const options = new PrivaLocalizationOptions();
    options.path = 'assets/translations';
    options.version = Date.now().toString();
    options.availableLanguagesResource = 'assets/translations/locales.json';
    options.defaultLanguage = 'en-US';
    return options;
}

export function getVersionBasedTranslationLoader(
    http: HttpClient,
    options: PrivaLocalizationOptions,
): VersionBasedTranslationLoader {
    return new VersionBasedTranslationLoader(http, options);
}
