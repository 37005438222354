import { createSelector } from '@ngrx/store';

import { ControllerProtocolSettings, SystemComponent } from '@priva/next-model';

import { ConnectivityStateContainer } from './connectivity.state';

export const controllerProtocolDeviceTemplate = createSelector(
    (state: ConnectivityStateContainer) => state.siteLibrary?.activeSystemComponent,
    (state: ConnectivityStateContainer) => state.connectivity?.controllerProtocolSettings,
    (deviceTemplate?: SystemComponent, controllerProtocolSettings?: ControllerProtocolSettings) => {
        if (!deviceTemplate || !controllerProtocolSettings) {
            return undefined;
        }
        return controllerProtocolSettings?.id === deviceTemplate?.id ? controllerProtocolSettings : undefined;
    },
);

export const selectControllerProtocolSettings = (state: ConnectivityStateContainer) =>
    state.connectivity.controllerProtocolSettings;

export const selectExpansionModules = createSelector(
    selectControllerProtocolSettings,
    (protocolSettings: ControllerProtocolSettings) => protocolSettings.expansionModules,
);

export const selectMaxSupportedExpansionModulesCount = createSelector(
    selectControllerProtocolSettings,
    (protocolSettings: ControllerProtocolSettings) => protocolSettings.maxSupportedExpansionModulesCount,
);
