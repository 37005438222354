import { ControllerProtocolSettings } from '@priva/next-model';

import { SiteLibraryState } from 'app/site-library/state';

export interface ConnectivityStateContainer {
    siteLibrary: SiteLibraryState;
    connectivity: ConnectivityState;
}

export interface ConnectivityState {
    controllerProtocolSettings: ControllerProtocolSettings;
    isChanged: boolean;
}

export const initialState: ConnectivityState = {
    controllerProtocolSettings: undefined,
    isChanged: false,
};
