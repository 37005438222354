import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, from, Observable } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';

import { Solution } from '@priva/next-model';

import { X_HEADER } from 'app/core/http';
import { AppStateContainer } from 'app/state';

import { getAllSolutions } from '../../../solutions/state/solutions.actions';
import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import { RequestLocalStorageService } from '../../local-storage/request-local-storage.service';

@Injectable()
export class OfflineSyncSolutionsInterceptor implements HttpInterceptor {
    private localStorageService = inject(NextLocalStorageService);
    private requestLocalStorage = inject(RequestLocalStorageService);
    private readonly store = inject<Store<AppStateContainer>>(Store);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) !== getAllSolutions.type) {
            return next.handle(request);
        }

        return this.store.pipe(
            first(),
            switchMap((state) => {
                if (state.app.online) {
                    return next.handle(request).pipe(
                        mergeMap(async (event) => {
                            if (!(event instanceof HttpResponse)) {
                                return event;
                            }
                            const response = event as HttpResponse<Solution[]>;
                            await this.enhanceSolutions(response.body);
                            return event;
                        }),
                    );
                } else {
                    return from(this.localStorageService.getSolutions()).pipe(
                        switchMap((solutions: Solution[]) => this.enhanceSolutions(solutions)),
                        map((solutions: Solution[]) => new HttpResponse({ body: solutions })),
                    );
                }
            }),
        );
    }

    private async enhanceSolutions(solutions: Solution[]): Promise<Solution[]> {
        for (const solution of solutions) {
            solution.autoSync = await this.localStorageService.isAutoSync(solution.id);
            solution.offline = await this.localStorageService.exists(solution.id);
            solution.changesCount = await firstValueFrom(
                this.requestLocalStorage.getRequestCount(solution.id),
            );
        }
        return solutions;
    }
}
