import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { IOPinProtocolMapping, Riom } from '@priva/next-model';

import { NextLocalStorageService, RequestLocalStorageService } from 'app/common/local-storage';
import { X_HEADER } from 'app/core/http';
import { IOMappingActions } from 'app/solution/element/state/io-mapping';
import { SolutionStateContainer } from 'app/solution/state';

import { extractRiomIdFromNextApi, extractSolutionIdFromNextApi } from '../offline-sync.helper';

@Injectable()
export class OfflineSyncIoMappingInterceptor implements HttpInterceptor {
    private readonly nextLocalStorageService = inject(NextLocalStorageService);
    private readonly store = inject<Store<SolutionStateContainer>>(Store);
    private readonly requestLocalStorageService = inject(RequestLocalStorageService);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.get(X_HEADER) === IOMappingActions.getRiom.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const riomId = extractRiomIdFromNextApi(request.url);

            return this.store.pipe(
                first(),
                switchMap((state) =>
                    state.solution?.active?.offline
                        ? from(this.nextLocalStorageService.getRiom(solutionId, riomId)).pipe(
                              map((riom: Riom) => new HttpResponse({ body: riom })),
                          )
                        : next.handle(request),
                ),
            );
        }

        if (request.headers.get(X_HEADER) === IOMappingActions.updateRiomIOPinValidation.type) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const riomId = extractRiomIdFromNextApi(request.url);

            return this.store.pipe(
                first(),
                switchMap((state) =>
                    state.solution?.active?.offline
                        ? from(this.requestLocalStorageService.add(solutionId, request)).pipe(
                              switchMap(() =>
                                  from(
                                      this.nextLocalStorageService.updateRiomIOPinValidation(
                                          solutionId,
                                          riomId,
                                          request.body as IOPinProtocolMapping,
                                      ),
                                  ).pipe(map((riom: Riom) => new HttpResponse({ body: riom }))),
                              ),
                          )
                        : next.handle(request),
                ),
            );
        }

        return next.handle(request);
    }
}
