import { inject, Injectable } from '@angular/core';

import { AppConfigurationService } from '../app-configuration/app-configuration.service';

@Injectable({
    providedIn: 'root',
})
export class FeatureTogglesApiPaths {
    private readonly appConfigurationService = inject(AppConfigurationService);
    public baseUrl: string;

    constructor() {
        this.baseUrl = this.appConfigurationService.configuration.uris.featureTogglesApiUrl;
    }

    public get apiVersion() {
        return 'v1';
    }

    public get apiUri() {
        return `${this.baseUrl}/api`;
    }

    public me(): string {
        return `${this.apiUri}/feature-toggles/me`;
    }
}
