import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, shareReplay } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { WINDOW_TOKEN } from 'app/common/window.token';

import { FeatureTogglesApiPaths } from '../../configuration/api-paths/feature-toggles-api.paths';
import { FeatureToggle } from './feature-toggles.model';

@Injectable({ providedIn: 'root' })
export class FeatureTogglesService {
    private readonly httpClient = inject(HttpClient);
    private readonly featureTogglesApiPaths = inject(FeatureTogglesApiPaths);
    private readonly window = inject(WINDOW_TOKEN);

    private userFeaturesSubject = new ReplaySubject<FeatureToggle[]>(1);

    public userFeatures$ = this.userFeaturesSubject.pipe(shareReplay({ bufferSize: 1, refCount: true }));

    public hasFeatures(requiredFeatures: string[]): Observable<boolean> {
        return this.userFeatures$.pipe(
            map((userFeatures: FeatureToggle[]) => {
                const userFeaturesNames: string[] = userFeatures.map((f: FeatureToggle) => f.technicalName);
                const targetFeatures: FeatureToggle[] = userFeatures.filter((feature: FeatureToggle) =>
                    requiredFeatures.includes(feature.technicalName),
                );

                return (
                    !this.window.privaConfig.useFeatureToggles ||
                    (targetFeatures.every((f: FeatureToggle) => f.enabled) &&
                        requiredFeatures.every((f: string) => userFeaturesNames.includes(f)))
                );
            }),
        );
    }

    public me(): Observable<FeatureToggle[]> {
        return this.httpClient.get<FeatureToggle[]>(this.featureTogglesApiPaths.me());
    }

    public init() {
        this.me()
            .pipe(
                take(1),
                tap((features) => this.userFeaturesSubject.next(features)),
            )
            .subscribe();
    }
}
