import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    ENVIRONMENT_INITIALIZER,
    inject,
    InjectionToken,
    makeEnvironmentProviders,
    PLATFORM_ID,
} from '@angular/core';
import { filter, take, tap } from 'rxjs/operators';

import { CreatorFeatureToggles } from '../../common/feature-toggles/feature-toggles.model';
import { FeatureTogglesService } from '../../common/feature-toggles/feature-toggles.service';
import { PiwikConfig, WINDOW_TOKEN } from '../../common/window.token';

const PIWIK_CONFIG_TOKEN = new InjectionToken<PiwikConfig>('piwik.config');

function piwikScript(projectId: string): string {
    projectId = projectId?.replace(/[^a-zA-Z0-9-_]/g, '');
    return `(function (window, document, dataLayerName, id) {
                (window[dataLayerName] = window[dataLayerName] || []),
                    window[dataLayerName].push({ start: new Date().getTime(), event: 'stg.start' });
                var scripts = document.getElementsByTagName('script')[0],
                    tags = document.createElement('script');
                function stgCreateCookie(a, b, c) {
                    var d = '';
                    if (c) {
                        var e = new Date();
                        e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3), (d = '; expires=' + e.toUTCString());
                        f = '; SameSite=Strict';
                    }
                    document.cookie = a + '=' + b + d + f + '; path=/';
                }
                var isStgDebug =
                    (window.location.href.match('stg_debug') || document.cookie.match('stg_debug')) &&
                    !window.location.href.match('stg_disable_debug');
                stgCreateCookie('stg_debug', isStgDebug ? 1 : '', isStgDebug ? 14 : -1);
                var qP = [];
                dataLayerName !== 'dataLayer' && qP.push('data_layer_name=' + dataLayerName),
                isStgDebug && qP.push('stg_debug');
                var qPString = qP.length > 0 ? '?' + qP.join('&') : '';
                (tags.async = !0),
                    (tags.src = 'https://creator.containers.piwik.pro/' + id + '.js' + qPString),
                    scripts.parentNode.insertBefore(tags, scripts);
                !(function (a, n, i) {
                    a[n] = a[n] || {};
                    for (var c = 0; c < i.length; c++)
                        !(function (i) {
                            (a[n][i] = a[n][i] || {}),
                                (a[n][i].api =
                                    a[n][i].api ||
                                    function () {
                                        var a = [].slice.call(arguments, 0);
                                        'string' == typeof a[0] &&
                                        window[dataLayerName].push({
                                            event: n + '.' + i + ':' + a[0],
                                            parameters: [].slice.call(arguments, 1),
                                        });
                                    });
                        })(i[c]);
                })(window, 'ppms', ['tm', 'cm']);
            })(window, document, 'dataLayer', '1a2d9686-858a-4b24-a74b-6692f084eb0c');`;
}

export function providePiwik() {
    const piwikConfigFactory = () => inject(WINDOW_TOKEN).privaConfig.piwik ?? {};

    if (!piwikConfigFactory) return makeEnvironmentProviders([]);
    return makeEnvironmentProviders([
        { provide: PIWIK_CONFIG_TOKEN, useFactory: piwikConfigFactory, deps: [WINDOW_TOKEN] },
        {
            provide: ENVIRONMENT_INITIALIZER,
            useFactory: (
                platformId: object,
                document: Document,
                piwikConfig: PiwikConfig,
                featureTogglesService: FeatureTogglesService,
            ) => {
                return () => {
                    if (
                        isPlatformBrowser(platformId)
                        // TODO (BW): uncomment when piwik config passed via appSettings
                        // piwikConfig?.enabled &&
                        // piwikConfig?.projectId?.trim() !== ''
                    ) {
                        featureTogglesService
                            .hasFeatures([CreatorFeatureToggles.ANALYTICS])
                            .pipe(
                                take(1),
                                filter((hasFeatures) => hasFeatures),
                                tap(() => {
                                    const scriptElement = document.createElement('script');
                                    scriptElement.type = 'text/javascript';
                                    scriptElement.defer = true;
                                    scriptElement.textContent = piwikScript(piwikConfig?.projectId);
                                    document.head.appendChild(scriptElement);
                                }),
                            )
                            .subscribe();
                    }
                };
            },
            deps: [PLATFORM_ID, DOCUMENT, PIWIK_CONFIG_TOKEN, FeatureTogglesService],
            multi: true,
        },
    ]);
}
