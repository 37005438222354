import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    inject,
    OnInit,
    viewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { Solution } from '@priva/next-model';

import { EntityType } from 'app/shared/entity';
import { SolutionActions, SolutionStateContainer } from 'app/solution/state';
import { AppActions } from 'app/state';

import { SearchGroupType, SearchTab } from '../search-group.enum';
import { SearchResult, SearchResultGroup } from '../search-result.model';
import { SearchActions, SearchStateContainer } from '../state';

interface ExpandableGroup extends SearchResultGroup {
    isExpanded: boolean;
    singleResult: boolean;
    hasResult: boolean;
}

@Component({
    selector: 'priva-dialog-content',
    templateUrl: './search-dialog.component.html',
    styleUrl: './search-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchDialogComponent implements OnInit, AfterViewInit {
    private readonly store = inject<Store<SolutionStateContainer & SearchStateContainer>>(Store);
    public SearchTab = SearchTab;
    public entityTypes = EntityType;

    private readonly resultGroups$: Observable<SearchResultGroup[]>;
    public expandableGroups$: Observable<ExpandableGroup[]>;
    public solution$: Observable<Solution>;

    public searchField = viewChild<ElementRef>('searchField');

    public activeTab: SearchTab = SearchTab.All;
    public searchTerms: string[];
    public solutionId: string;
    public notImplementedYet = true;

    constructor() {
        this.resultGroups$ = this.store.select((s) => s.search.resultGroups);
        this.solution$ = this.store
            .select((s) => s.solution?.active)
            .pipe(
                filter((s) => !!s),
                tap((s) => (this.solutionId = s.id)),
            );
    }

    public selectTab(tab: SearchTab) {
        this.activeTab = tab;

        this.store.dispatch(
            SearchActions.getSearchResults({
                solutionId: this.solutionId,
                searchTerms: [this.searchField().nativeElement.value],
                searchFilter: this.getFilter(tab),
            }),
        );
    }

    public close() {
        this.store.dispatch(AppActions.closeDialog({}));
    }

    public ngOnInit() {
        this.expandableGroups$ = fromEvent<KeyboardEvent>(this.searchField().nativeElement, 'input')
            .pipe(
                map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
                distinctUntilChanged(),
                debounceTime(200),
                filter((term) => !!term && term.length >= 2),
                tap((term) => {
                    this.searchOnTerm(term);
                    this.searchTerms = term ? [term] : undefined;
                }),
                switchMap(() => this.resultGroups$),
            )
            .pipe(
                map((groups) =>
                    this.mapToExpandableGroups(groups).sort((s1, s2) =>
                        s1.searchResultGroupId < s2.searchResultGroupId ||
                        s2.searchResultGroupId === SearchGroupType.Building
                            ? -1
                            : 1,
                    ),
                ),
            );
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            this.searchField().nativeElement.focus();
        }, 0);
    }

    public toggleExpand(expandableGroup: ExpandableGroup) {
        expandableGroup.isExpanded = !expandableGroup.isExpanded;
    }

    private searchOnTerm(term: string): void {
        if (this.notImplementedYet) {
            return;
        }

        this.store.dispatch(
            SearchActions.getSearchResults({
                solutionId: this.solutionId,
                searchTerms: [term],
                searchFilter: this.getFilter(this.activeTab),
            }),
        );
    }

    private getFilter(tab: SearchTab): SearchGroupType {
        switch (tab) {
            case SearchTab.All:
                return undefined;
            case SearchTab.Building:
                return SearchGroupType.Building;
            case SearchTab.DeviceTemplates:
                return SearchGroupType.DeviceTemplates;
            case SearchTab.DeviceTemplateGroups:
                return SearchGroupType.DeviceTemplateGroups;
            default:
                return undefined;
        }
    }

    public navigateTo(searchResult: SearchResult) {
        let urlParts: string[];
        switch (searchResult.entityType) {
            case EntityType.DeviceTemplate:
                urlParts = ['templates', searchResult.entity.id];
                break;
            case EntityType.DeviceTemplateGroup:
                urlParts = ['templates', 'groups', searchResult.entity.id];
                break;
            case EntityType.Zone:
                urlParts = ['topology', searchResult.entity.id];
                break;
            case EntityType.Element:
                urlParts = ['topology', searchResult.entityProperties.find((e) => e.id === 'zoneId').value];
                break;
            default:
                throw new Error(`Unhandled search type: ${searchResult.entityType}`);
        }

        this.store.dispatch(
            SolutionActions.navigateToSolutionAsset({
                urlParts,
                state: { continueWithDefaultAction: false },
            }),
        );
        this.store.dispatch(AppActions.closeDialog({}));
    }

    private mapToExpandableGroups(groups: SearchResultGroup[]): ExpandableGroup[] {
        const expandableGroups: ExpandableGroup[] = [];
        groups.forEach((g) => {
            const eg = { ...g } as ExpandableGroup;
            eg.isExpanded = !!g.results.length;
            eg.singleResult = g.results.length === 1;
            eg.hasResult = !!g.results[0];
            expandableGroups.push(eg);
        });

        return expandableGroups;
    }
}
