import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    ENVIRONMENT_INITIALIZER,
    inject,
    InjectionToken,
    makeEnvironmentProviders,
    PLATFORM_ID,
} from '@angular/core';

import { ClarityConfig, WINDOW_TOKEN } from 'app/common/window.token';

const CLARITY_CONFIG_TOKEN = new InjectionToken<ClarityConfig>('clarity.config');

function clarityScript(projectId: string): string {
    projectId = projectId.replace(/[^a-zA-Z0-9-_]/g, '');
    return `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+encodeURIComponent(i);
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${projectId}");`;
}

export function provideClarity() {
    const clarityConfigFactory = () => inject(WINDOW_TOKEN).privaConfig.clarity;

    if (!clarityConfigFactory) return makeEnvironmentProviders([]);
    return makeEnvironmentProviders([
        { provide: CLARITY_CONFIG_TOKEN, useFactory: clarityConfigFactory, deps: [WINDOW_TOKEN] },
        {
            provide: ENVIRONMENT_INITIALIZER,
            useFactory: (platformId: object, document: Document, clarityConfig: ClarityConfig) => {
                return () => {
                    if (
                        isPlatformBrowser(platformId) &&
                        clarityConfig?.enabled &&
                        clarityConfig?.projectId?.trim() !== ''
                    ) {
                        const scriptElement = document.createElement('script');
                        scriptElement.type = 'text/javascript';
                        scriptElement.defer = true;
                        scriptElement.textContent = clarityScript(clarityConfig.projectId);
                        document.head.appendChild(scriptElement);
                    }
                };
            },
            deps: [PLATFORM_ID, DOCUMENT, CLARITY_CONFIG_TOKEN],
            multi: true,
        },
    ]);
}
