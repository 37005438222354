import { InjectionToken, Provider } from '@angular/core';

export const WINDOW_TOKEN = new InjectionToken<WindowWithPrivaConfig>('WINDOW_TOKEN');

export const provideWindow = (windowObject: typeof window = window): Provider[] => [
    { provide: WINDOW_TOKEN, useValue: windowObject },
];

export interface PrivaConfig {
    name: string;
    components: object;
    authentication: object;
    uris: object;
    useFeatureToggles: boolean;
    clarity: ClarityConfig;
    piwik: PiwikConfig;
}

export type WindowWithPrivaConfig = typeof window & { privaConfig: PrivaConfig };
export type ClarityConfig = Readonly<{
    enabled: boolean;
    projectId: string;
}>;
export type PiwikConfig = Readonly<{
    enabled: boolean;
    projectId: string;
}>;
