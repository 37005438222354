import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {
    AppShellAreaService,
    AppShellAreaType,
    AppShellComponentBroadcastService,
    AppShellComponentType,
} from '@priva/appshell';
import { Viewport } from '@priva/components-candidates/app-screen-size-detector';
import { PrivaNotificationsService } from '@priva/components/notifications';
import { NextError } from '@priva/next-model';

import { FeatureTogglesService } from 'app/common/feature-toggles/feature-toggles.service';

import { SearchDialogComponent } from './search';
import { AppActions, AppStateContainer } from './state';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrl: 'app.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly store$ = inject<Store<AppStateContainer>>(Store);
    private readonly notificationService = inject(PrivaNotificationsService);
    private readonly appShellAreaService = inject(AppShellAreaService);
    private readonly appshellComponentBroadcastService = inject(AppShellComponentBroadcastService);
    private readonly featureTogglesService = inject(FeatureTogglesService);
    public readonly AreaTypes = AppShellAreaType;

    // Component existence : visibility
    public hasNotificationBar = false;
    public hasPrimaryNav = true;

    public throbbers$: Observable<any>;
    public error$: Observable<NextError | undefined>;
    private readonly unsubscribe$: Subject<void> = new Subject();

    constructor() {
        this.throbbers$ = this.store$.select((s) => s.app.throbbers);
        this.error$ = this.store$.select((s) => s.app.error);

        // Has notification bar
        this.notificationService.barVisibility
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((visible) => (this.hasNotificationBar = visible));

        // Has primary nav
        this.appShellAreaService
            .hasArea(AppShellAreaType.PrimaryNav)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((available: boolean) => (this.hasPrimaryNav = available));

        // Has global search
        this.appshellComponentBroadcastService.broadcasts
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((type: AppShellComponentType) => type === AppShellComponentType.AppSearch),
            )
            .subscribe(() => this.showSearchDialog());
    }

    public async ngOnInit() {
        await this.initializeStorage();
        this.featureTogglesService.init();
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /* istanbul ignore next ec, just dispatch action */
    public onMinWidthExceeded(viewport: Viewport): void {
        this.store$.dispatch(AppActions.screenMinimumWidthUndershot({ viewport }));
    }

    private async initializeStorage() {
        return navigator.storage?.persist();
    }

    private showSearchDialog() {
        this.store$.dispatch(
            AppActions.openDialog({
                dialog: {
                    component: SearchDialogComponent,
                },
            }),
        );
    }

    public dispatchAction(action: Action) {
        this.store$.dispatch(action);
    }
}
