export interface FeatureToggle {
    serviceTechnicalName: string;
    technicalName: string;
    enabled: boolean;
}

export enum CreatorFeatureToggles {
    BACNET = 'nuro-creator-bacnet',
    ANALYTICS = 'nuro-creator-analytics',
}
